// Code adapted from: https://code.amazon.com/packages/AmazonUIPageJS/blobs/37dbeea6ca1567f251c49b9f619211a4dc21a108/--/src/_load.js#L133
export function loadCSS(url, metricsId, onSuccess) {
  const element = createCSSElement(url);
  loadElement(element, metricsId, onSuccess);
}

export function loadScript(url, isAsync, metricsId, onSuccess) {
  const element = createScriptElement(url, isAsync);
  loadElement(element, metricsId, onSuccess);
}

function loadElement(element, metricsId, onSuccess) {
  logCounter(metricsId, 'loaded');
  element.addEventListener('error', evt => {
    logCounter(metricsId, 'error');
    if (evt && evt.stopPropagation) {
      evt.stopPropagation();
    }
  });
  if (onSuccess) {
    element.addEventListener('load', onSuccess);
  }
  document.querySelector('head').appendChild(element);
}

function logCounter(id, state) {
  const metric = id + 'Asset:' + state;
  if (window.KatalInternalMetricsAssetLogger) {
    window.KatalInternalMetricsAssetLogger(metric, 1);
  } else {
    if (!window.KatalDeferredAssetMetrics) {
      window.KatalDeferredAssetMetrics = {};
    }

    window.KatalDeferredAssetMetrics[metric] = 1;
  }
}

function createCSSElement(url) {
  const elem = document.createElement('link');
  elem.setAttribute('rel', 'stylesheet');
  elem.setAttribute('href', url);
  return elem;
}

function createScriptElement(url, isAsync) {
  const elem = document.createElement('script');
  elem.setAttribute('src', url);
  elem.setAttribute('crossorigin', 'anonymous');
  if (isAsync) {
    elem.setAttribute('async', '');
  } else {
    elem.async = false;
  }
  return elem;
}
