/**
 * The following global variables are defined by webpack:
 *
 * ASSET_LOADER_FILENAME_PREFIX: string
 * ASSET_CONFIGURATION: string
 * METRICS_FILENAME: string
 */

const configuration = ASSET_CONFIGURATION;
const metricsFilename = METRICS_FILENAME;
const assetLoaderFilenamePrefix = ASSET_LOADER_FILENAME_PREFIX;

require('./polyfills/promise').polyfill();
require('./polyfills/fetch');
require('custom-event-polyfill');
import { loadCSS, loadScript } from './utils/load-file';
import isPageDirectionRightToLeft from './utils/rtl-detect';

/**
 * This will be removed in a future major version
 * @deprecated
 */
require('url-polyfill');

window.Katal = window.Katal || {};
window.Katal.polyfills = {};

// See https://github.com/webcomponents/webcomponentsjs/blob/master/webcomponents-loader.js#L31
const polyfillsRequired = (() => {
  const customElementsPolyfillRequired = !('customElements' in window);
  const templateTagPolyfillRequired = !(
    'content' in document.createElement('template')
  );
  const promisesPolyfillRequired = !window.Promise;
  const arrayFromConstructorPolyfillRequired = !Array.from;
  const templateCloningDocumentFragmentsPolyfillRequired = !(
    document.createDocumentFragment().cloneNode() instanceof DocumentFragment
  );

  //set some variables that we can use to write metrics out with
  const polyfills = window.Katal.polyfills;

  polyfills.customElements = customElementsPolyfillRequired;
  polyfills.templateTag = templateTagPolyfillRequired;
  polyfills.promises = promisesPolyfillRequired;
  polyfills.arrayFromConstructor = arrayFromConstructorPolyfillRequired;
  polyfills.templateCloningDocumentFragments = templateCloningDocumentFragmentsPolyfillRequired;

  return (
    customElementsPolyfillRequired ||
    templateTagPolyfillRequired ||
    promisesPolyfillRequired ||
    arrayFromConstructorPolyfillRequired ||
    templateCloningDocumentFragmentsPolyfillRequired
  );
})();

const assetLoaderScriptTag = document.querySelector(
  'script[src*="' + assetLoaderFilenamePrefix + '"]'
);
let assetLoaderProtocolAndDomain;

// Read the <script> tag that injected this file and extract the domain if we can, else default back to prod
if (assetLoaderScriptTag) {
  assetLoaderProtocolAndDomain = assetLoaderScriptTag
    .getAttribute('src')
    .split(assetLoaderFilenamePrefix)[0];
} else {
  assetLoaderProtocolAndDomain = 'https://d1uznvntk80v7s.cloudfront.net/';
}

window.Katal.assetLoaderRoot = assetLoaderProtocolAndDomain;

// If the page direction is RTL, the asset loader injects the RTL css file instead of the original one
const cssKey = isPageDirectionRightToLeft() ? 'css-rtl' : 'css';
const filename =
  assetLoaderProtocolAndDomain + configuration.assetFilenames[cssKey];

loadCSS(filename, 'ComponentsCSS');

function writeKatalComponentsScriptTag(polyfillAndTranspilationRequired) {
  const url =
    assetLoaderProtocolAndDomain +
    configuration.assetFilenames[
      polyfillAndTranspilationRequired ? 'es5min' : 'es6min'
    ];

  // Polyfill needs to be loaded synchronously, always, so the components don't run before it's complete
  loadScript(url, !polyfillAndTranspilationRequired, 'Components');
}

// Add metrics to the page
// Should come before components code so the event listener in metrics happens before
if (metricsFilename) {
  const metricsSrc = assetLoaderProtocolAndDomain + metricsFilename;
  loadScript(metricsSrc, true, 'KatalInternalMetrics');
}

const fire = () => {
  requestAnimationFrame(() => {
    document.dispatchEvent(
      new CustomEvent('WebComponentsReady', { bubbles: true })
    );
  });
};

if (document.readyState !== 'loading') {
  fire();
} else {
  document.addEventListener('readystatechange', function wait() {
    fire();
    document.removeEventListener('readystatechange', wait);
  });
}

// JS Injection:
// If we need the polyfill loaded, write that out now
// And then wait to inject the Katal components script tag until the polyfill is ready
if (polyfillsRequired) {
  loadScript(
    assetLoaderProtocolAndDomain + 'custom-elements_1_2_2.min.js',
    false,
    'CustomElements'
  );
  writeKatalComponentsScriptTag(true);
} else {
  // Inject main JS asset
  writeKatalComponentsScriptTag(false);
}
